'use client';

import React from 'react';
import { HelmetProvider } from 'react-helmet-async';

const HELMET_CONTEXT = {};

const RootLayout = ({ children }) => (
  <HelmetProvider context={HELMET_CONTEXT}>
    {children}
  </HelmetProvider>
);

export default RootLayout;
